<template>
  <div style="margin-bottom: 3em">
    <!-- <Sidebar /> -->
    <HeaderPage />
    <div class="mt-15 ps-3 pb-3" height>
      <p class="ps-3 text_title">確認ページ</p>
    </div>
    <div class="pt-5 px-5" style="background-color: #e1dddd">
      <span>{{ $t("message.email") }} </span>
      <v-text-field v-model="email" outlined readonly></v-text-field>
      <span>{{ $t("message.content") }}</span>
      <v-textarea v-model="content" outlined readonly></v-textarea>
    </div>
    <v-card class=" px-5 pt-8 d-flex justify-space-between" flat tile style="margin-bottom: 8rem">
      <v-card flat tile>
        <v-btn
          depressed
          class="white--text"
          color="grey"
          @click.prevent="$router.go(-1)"
        >
          {{ $t("message.back") }}
        </v-btn>
      </v-card>
      <v-card flat tile>
        <v-btn
          depressed
          class="white--text"
          color="#ff0090"
          @click="submitForm"
        >
          {{ $t("message.btn-send") }}
        </v-btn>
      </v-card>
    </v-card>
    <SnackBar
      v-if="openSnackbar"
      :snackbar="openSnackbar"
      :message="$t('message.success-contact')"
    />
    <Sidebar />
  </div>
</template>

<script>
import Sidebar from '../../components/developmentv2/Sidebar.vue'
import HeaderPage from '../../components/developmentv2/HeaderPage.vue'
import SnackBar from "../../components/Snackbar.vue";

// import { VueReCaptcha } from 'vue-recaptcha-v3';
// // console.log(process.env);
// Vue.use(VueReCaptcha, {
//   siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
//   loaderOptions: {
//     autoHideBadge: true
//   }
// });

export default {
  name: "ConfirmationPage",
  components: {
    Sidebar,
    SnackBar,
    HeaderPage
  },
  data() {
    return {
      email: "",
      content: "",
    };
  },
  beforeMount() {},
  created() {
    this.getValue();
  },
  computed: {
    openSnackbar() {
      return this.$store.state.about_module.openSnackbar;
    },
  },
  methods: {
    getValue() {
      if (localStorage.cntc) {
        let parsedContact = JSON.parse(localStorage.getItem("cntc"));
        this.email = parsedContact.email;
        this.content = parsedContact.content;

      } else {
        this.email= ""
        this.content= ""
      }
    },
    async submitForm() {
      let data = JSON.parse(localStorage.getItem("cntc"));

      await this.$recaptchaLoaded();
      data.recaptchatoken = await this.$recaptcha('login');

      this.$store.dispatch("about_module/submitContact", data);
      localStorage.removeItem("cntc")
    },
  },
};
</script>

<style>
.text_title {
  border-left: 10px solid #ff0090;
  font-size: 18px;
}
</style>
